<template>
  <div class="i-wrap" ref="printPage">
    <div class="i-main">
      <div class="red-wrap">
        <div class="red-header">
          <div class="header-title">红字增值税专用发票信息表</div>
        </div>
        <div class="red-content">
          <div class="red-info">
            <div>
              <div class="info-label">销售方</div>
              <div class="info-area">
                <div>
                  <label class="required">名 称：</label>
                  <span>{{ redNotice.sellerName }}</span>
                </div>
                <div>
                  <label>税 号：</label>
                  <span>{{ redNotice.sellerTaxNo }}</span>
                </div>
              </div>
            </div>
            <div>
              <div class="info-label">购买方</div>
              <div class="info-area">
                <div>
                  <label>名 称：</label>
                  <span>{{ redNotice.buyerName }}</span>
                </div>
                <div>
                  <label>税 号：</label>
                  <span>{{ redNotice.buyerTaxNo }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="red-items">
            <table>
              <thead>
                <tr>
                  <th width="20"></th>
                  <th>项目名称</th>
                  <th>规格</th>
                  <th>单位</th>
                  <th>数量</th>
                  <th>单价（含税）</th>
                  <th>金额（含税）</th>
                  <th>税率</th>
                  <th>税额</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in info.items" :key="index">
                  <td :rowspan="info.items.length + 3" v-if="index == 0" style="writing-mode: vertical-lr; max-width: 40px">开具红字发票确认单内容</td>
                  <td>{{ item.merchandiseName }}</td>
                  <td>{{ item.specification }}</td>
                  <td>{{ item.unit }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ fmtMoney(item.price) }}</td>
                  <td>{{ fmtMoney(item.sumAmount) }}</td>
                  <td>{{ fmtTaxRate(item.taxRate) }}</td>
                  <td>{{ fmtMoney(item.taxAmount) }}</td>
                </tr>
                <tr class="invoice_total_box">
                  <td style="color: #9d5224">价税合计（大写）</td>
                  <td class="nrb" colspan="7" style="">
                    <el-row>
                      <el-col :span="12"><div> {{ info.dxSumAmount }}</div></el-col>
                      <el-col :span="12"><div>(小写){{ info.xxSumAmount }}</div></el-col>
                    </el-row>
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <el-row class="pd_20">
                      <el-row class="c_label t_l">一、录入方信息</el-row>
                      <el-row class="t_l">
                        <el-radio-group v-model="this.redNotice.redSource">
                          <el-radio :disabled="this.redNotice.redSource == 'seller'" label="buyer">购买方申请</el-radio>
                          <el-radio :disabled="this.redNotice.redSource == 'buyer'" label="seller">销售方申请</el-radio>
                        </el-radio-group>
                      </el-row>
                      <el-row class="c_label t_l">二、红冲原因{{this.redNotice.applyRemark}}</el-row>
                      <el-row class="t_l">
                        <el-radio-group v-model="this.redNotice.applyRemark">
                          <el-radio :disabled="this.redNotice.applyRemark != '01'" label="01">开票有误</el-radio>
                          <el-radio :disabled="this.redNotice.applyRemark != '02'" label="02">销货退回</el-radio>
                          <el-radio :disabled="this.redNotice.applyRemark != '03'" label="03">服务中止</el-radio>
                          <el-radio :disabled="this.redNotice.applyRemark != '04'" label="04">销售折让</el-radio>
                        </el-radio-group>
                      </el-row>
                    </el-row>
                  </td>
                  <td colspan="4">
                    <el-row class="pb">
                      <el-col class="t_l"><span class="c_label">三、蓝字发票号码：</span>{{ info.invoiceNo }}</el-col>
                      <el-col class="t_l"><span class="c_label">四、红字通知单编号：</span>{{ redNotice.redNoticeNo }}</el-col>
                    </el-row>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import printJS from 'print-js';
import { tableStyle, changeMethods } from '@/util/mixins';

export default {
  name: 'BillingInvoiceDetail',
  mixins: [tableStyle, changeMethods],
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    redNotice: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      rRadio: 1,
      lRadio: 1,
      isPrint: false,
      // 申请方经办人
      nickName: localStorage.getItem('nickName'),
      xxSumAmount: null,
      dxSumAmount: null
    };
  },
  created() {
  },
  methods: {
    handleSum(){
      let sumAmount = this.info.items.map((item) => Number(item.amount)).reduce((sum, amount) => sum + amount, 0);
      let sumTaxAmount = this.info.items.map((item) => Number(item.taxAmount)).reduce((sum, taxAmount) => sum + taxAmount, 0);
      let sum = Number(sumAmount + sumTaxAmount).toFixed(2);
      this.dxSumAmount = this.handleSmallToCapitalize(sum);
      this.xxSumAmount = sum
    },
    // 详情打印
    handlePrint() {
      this.isPrint = true;
      this.$nextTick(() => {
        html2canvas(this.$refs.printPage, {
          backgroundColor: null,
          useCORS: true,
          windowHeight: document.body.scrollHeight
        }).then((canvas) => {
          this.isPrint = false;
          const url = canvas.toDataURL();
          this.img = url;
          printJS({
            printable: url,
            type: 'image',
            documentTitle: '打印图片'
          });
        });
      });
    },
    fmtMoney(val) {
      return val ? '¥' + val : '';
    },
    fmtTaxRate(val) {
      return val ? val * 100 + '%' : '';
    },
    fmtTime(val) {
      return this.$moment(val).format('YYYY-MM-DD');
    }
  }
};
</script>

<style lang="scss" scoped>
.c_label {
  color: #9d5224;
}
.t_l {
  text-align: left;
  // margin-left: 20px;
}
.pd_20 {
  padding: 20px;
}
.pb {
  margin: -60px 0 0 20px;
}

table {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    font-weight: 400;
    border: 1px solid #9d5224;
    height: 32px;
    line-height: 32px;
  }
}

.i-wrap {
  font-size: 14px;
  margin-top: -50px;
  min-height: 100%;
  background-color: #ffffff;

  td,
  .black {
    color: #333333;
  }
}

.red-wrap {
  display: flex;
  padding: 24px;
  color: #9d5224;
  align-items: center;
  flex-direction: column;

  .red-header {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 18px;
    flex-direction: column;

    .header-title {
      font-size: 32px;
      margin-bottom: 28px;
      border-bottom: 4px double;
    }

    .print {
      margin-left: 500px;
      margin-top: 20px;
      position: absolute;
    }

    .header-items {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      div:first-child {
        margin-right: 100px;
      }

      span {
        padding-left: 10px;
        color: #333333;
      }
    }
  }

  .red-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .red-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;

      .info-label {
        width: auto;
        display: flex;
        padding: 24px 11px;
        letter-spacing: 6px;
        align-items: center;
        justify-content: center;
        border-right: 1px solid;
        writing-mode: vertical-lr;
      }

      .info-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        label {
          color: #9d5224;
          text-align: left;
        }

        span {
          text-align: left;
          width: 300px;
          color: #333333;
          display: inline-block;
        }

        .sellerName {
          width: 300px;
        }

        .sellerName ::v-deep .el-input {
          width: 100%;
          border: none;
        }

        .el-input {
          width: 300px;
          border: none;
        }

        ::v-deep .el-input__inner {
          border: none;
          border-radius: 0;
          background-color: #ffffff;
        }
      }
    }

    .red-info > div {
      width: 48%;
      display: flex;
      border: 1px solid;
      flex-direction: row;
      min-height: 100px;
    }

    .red-remark {
      font-size: 14px;
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      border: 1px solid;

      .remark-title {
        width: auto;
        padding: 24px 11px;
        letter-spacing: 6px;
        border-right: 1px solid;
        writing-mode: vertical-lr;
      }

      .notice-no {
        width: 60px;
        padding: 10px 8px;
        letter-spacing: 2px;
        writing-mode: unset;
        word-break: break-word;
      }

      .remark-content {
        flex: auto;
        padding: 24px;
        text-align: left;

        .deduction {
          display: flex;
          padding: 24px 24px;
          flex-direction: row;
        }
      }
    }
  }
}
</style>
