<template>
  <el-drawer append-to-body title="发票详情" :visible.sync="visiable" size="789px" :withHeader="false" @close="$emit('close')">
    <div class="open-invoice-details">
      <el-tabs v-model="activeName">
        <el-tab-pane label="发票详情" name="first">
          <div class="invoice-top">
            <label-span label="开具红字发票原因：" :span="fmtToRedRemark(invoice.applyRemark)" />
            <label-span label="确认状态：" :span="handleValueToLabel('ConfirmStatus', invoice.confirmStatus)" />
            <label-span label="发票状态：" :span="fmtInvoiceStatus(invoice.invoiceStatus)" />
          </div>
          <div class="invoice-middle">
            <div class="middle-average">
              <div class="second-row">
                <label style="min-width: 42px">票面金额:</label>
                <div>{{ invoice.amount }}</div>
              </div>
              <div class="first-row">
                <span>购买方</span>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">纳税人名称:</label>
                <div>{{ invoice.buyerName }}</div>
              </div>
              <div class="other-row">
                <label-span label="统一社会信用代码纳税人识别号：" :span="invoice.buyerTaxNo"></label-span>
              </div>
            </div>
            <div class="middle-average">
              <div class="second-row">
                <label style="min-width: 42px">全电发票号码:</label>
                <div>{{ invoice.invoiceNo }}</div>
              </div>
              <div class="first-row">
                <span>销售方</span>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">纳税人名称:</label>
                <div>{{ invoice.sellerName }}</div>
              </div>
              <div class="other-row">
                <label-span label="统一社会信用代码纳税人识别号：" :span="invoice.sellerTaxNo"></label-span>
              </div>
            </div>
          </div>
          <div class="invoice-bottom">
            <span>开票明细</span>
            <div class="bottom-table">
              <el-table :span-method="arraySpanMethod" :data="tableData" border stripe :header-cell-style="handleHeaderCellStyle" max-height="280">
                <el-table-column prop="merchandiseName" fixed width="150" label="项目名称" :formatter="fmtTableColumn" />
                <el-table-column prop="specification" label="规格型号" />
                <el-table-column prop="unit" label="单位" />
                <el-table-column prop="quantity" label="数量" />
                <el-table-column prop="unTaxPrice" label="单价(不含税)" />
                <el-table-column prop="amount" label="金额(不含税)" />
                <el-table-column prop="taxRate" label="税率" :formatter="fmtTableColumn" />
                <el-table-column prop="taxAmount" label="税额" :formatter="fmtTableColumn" />
              </el-table>
            </div>
          </div>
          <div class="open-invoice-message">
            <div class="one-col" style="margin-top: 30px">
              <div class="second-row">
                <label style="min-width: 42px">备注</label>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">购销身份 :</label>
                <div>销售方</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">红字确认单号 :</label>
                <div>{{ invoice.redNoticeNo }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">红票发票号码 :</label>
                <div>{{ invoice.redInvoiceNo }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">开票时间 :</label>
                <div>{{ invoice.invoiceTime }}</div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script>
import { invoiceReturn, selectInvoice } from '@/service/sal/invoice.js';
import { tableStyle, changeMethods } from '@/util/mixins';
import { getBillingRedNoticeDetails } from '@/service/invoice-open';

export default {
  name: 'BillingRedInvoiceDetail',
  mixins: [tableStyle, changeMethods],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    invoice: Object,
    invoiceItems: Object
  },
  data() {
    return {
      visiable: false,
      activeName: 'first',
      equBillingMode: [],
      blueInvoice: {},
      ToRedRemark: [
        {
          label: '开票有误',
          value: '01'
        },
        {
          label:'销货退回',
          value: '02'
        },
        {
          label: '服务中止',
          value: '03'
        },
        {
          label: '销售折让',
          value: '04'
        }
      ],
    };
  },
  created() {
    this.getDicts('equ_billing_mode').then(({ data }) => (this.equBillingMode = data));
  },
  watch: {
    show(val) {
      if (val) {
        this.handleDetail();
      }
      this.visiable = val;
    }
  },
  computed: {
    tableData() {
      return this.invoice.items;
    },
    sendBackInfo() {
      return this.invoice.sendBackInfo ? this.invoice.sendBackInfo : {};
    }
  },
  methods: {
    arraySpanMethod({ rowIndex, columnIndex }) {
      const openDetailsLength = this.invoice.items.length;
      if (rowIndex === openDetailsLength - 1) {
        if (columnIndex === 0) {
          return [1, 1];
        } else if (columnIndex === 1) {
          return [1, 4];
        } else {
          return [1, 3];
        }
      }
    },
    async handleDetail() {
      this.invoice.items.forEach((item) => (item.taxRate = Number(item.taxRate) * 100 + '%'));
      // 统计信息
      let sumAmount = this.invoice.items.map((item) => Number(item.amount)).reduce((sum, amount) => sum + amount, 0);
      let sumTaxAmount = this.invoice.items.map((item) => Number(item.taxAmount)).reduce((sum, taxAmount) => sum + taxAmount, 0);
      let total = {
        merchandiseName: '合计',
        amount: Number(sumAmount).toFixed(2),
        taxAmount: Number(sumTaxAmount).toFixed(2)
      };
      this.invoice.items.push(total);
      let sum = Number(sumAmount + sumTaxAmount).toFixed(2);
      let sumBig = this.handleSmallToCapitalize(sum);
      let priceTax = {
        merchandiseName: '价税合计（大写）',
        specification: sumBig,
        unit: '（小写）￥' + sum
      };
      this.invoice.items.push(priceTax);
    },
    isPreferential(val) {
      return val == '免税' || val == '不征税';
    },
    // 格式化税率、税额
    fmtTableColumn(row, column) {
      switch (column.property) {
        case 'taxRate':
          return this.isPreferential(row.taxPreferentialContent) ? row.taxPreferentialContent : row.taxRate;
        case 'taxAmount':
          return this.isPreferential(row.taxPreferentialContent) ? '***' : row.taxAmount;
        case 'merchandiseName':
          return row.taxClassName != null ? '*' + row.taxClassName + '*' + row.merchandiseName : row.merchandiseName;
      }
    },
    showMessage(message) {
      if (message == '' || message == undefined || message == null) {
        return '--';
      }
      return message;
    },
    handleTaxRate(val) {
      if (!isNaN(val)) {
        if (val == '') {
          return '';
        }
        return val * 100 + '%';
      } else {
        return val;
      }
    },
    fmtToRedRemark(row){
      var toredRemark = this.ToRedRemark.find((item) => item.value == row);
      if (toredRemark != null) {
        return toredRemark.label;
      }
      return toredRemark;
    },
    fmtInvoiceStatus(invoiceStatus){
      if(invoiceStatus == null || invoiceStatus == ''){
        return '未开具';
      }
      return this.handleValueToLabel('InvoiceStatus', invoiceStatus)
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.open-invoice-details {
  margin: 0 24px;

  ::v-deep .el-tabs__nav {
    margin: 0;
  }

  .invoice-top {
    display: flex;
    margin-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #cccccc;

    .label-span {
      margin-right: 86px;
    }
  }

  .invoice-middle {
    display: flex;
    margin-top: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid #cccccc;

    .middle-average {
      width: 400px;

      .first-row {
        margin-bottom: 16px;

        span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
        }
      }

      .second-row {
        display: flex;
        margin-bottom: 16px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          text-align: left;
          margin-right: 20px;
        }
      }

      .other-row {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        margin-right: 20px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  .invoice-bottom {
    margin-top: 32px;
    height: calc(100% - 225px);
    border-bottom: 1px solid #cccccc;
    padding-bottom: 32px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .bottom-table {
      margin-top: 16px;
    }
  }

  .invoice-remark {
    margin-top: 24px;

    .remark-title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .remark-content {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 24px;
      border-bottom: 1px solid #cccccc;
    }
  }

  .open-invoice-message {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-bottom: 24px;
    display: flex;

    .one-col {
      width: 400px;

      .second-row {
        display: flex;
        margin-bottom: 16px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          text-align: left;
          margin-right: 20px;
        }
      }
    }
  }
}

.el-row {
  margin-bottom: 16px;

  &:first-child {
    margin-top: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .label-span {
    span {
      margin-left: 8px;
    }
  }
}

::v-deep .el-drawer__body {
  overflow: auto;
}
</style>
