<template>
  <div class="billing_red_invoice_list_view">
    <!-- 红字发票确认信息录入 -->
    <RedInvoiceInfoInput v-if="isShowRedInvoiceInfo" :equipment="equipment" @handlerClone="handlerClone" @handleCloseApply="handleCloseApply" :invoiceId="$route.query.invoiceId" :step="$route.query.stepActive || 1" />
    <template v-else>
      <div class="content-select">
        <div class="select-content">
          <el-form ref="filterForm" :model="selectForm" inline label-width="130">
            <el-row class="row-first">
              <el-col :span="8">
                <el-form-item label="购/销身份" prop="status">
                  <el-select v-model="selectForm.redSource" placeholder="请选择">
                    <el-option label="销售方" value="seller"></el-option>
                    <el-option label="购买方" value="buyer"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="购方名称" prop="operName">
                  <el-input v-model="selectForm.buyerName" placeholder="请输入购方名称" clearable style="width: 220px" @keyup.enter.native="handleQuery" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="销方名称" prop="status">
                  <el-input v-model="selectForm.sellerName" placeholder="请输入销方名称" clearable style="width: 220px" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-first">
              <el-col :span="8">
                <el-form-item label="确认状态" prop="status">
                  <el-select v-model="selectForm.confirmStatus" placeholder="操作状态" clearable style="width: 220px">
                    <el-option v-for="dict in confirmStatus" :key="dict.value" :label="dict.label" :value="dict.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申领状态" prop="operName">
                  <el-select v-model="selectForm.applyStatus" placeholder="操作状态" clearable style="width: 220px">
                    <el-option v-for="dict in applyStatus" :key="dict.value" :label="dict.label" :value="dict.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="提交日期" prop="loginTime">
                  <el-date-picker v-model="selectForm.createdTimeRange" style="width: 220px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-first">
              <el-col :span="8">
                <el-form-item label="开票日期" prop="loginTime">
                  <el-date-picker v-model="selectForm.invoiceTimeRange" style="width: 220px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="录入日期" prop="loginTime">
                  <el-date-picker v-model="selectForm.enterTimeRange" style="width: 220px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-button type="primary" @click="handleQuery">查询</el-button>
                <el-button type="default" @click="resetQuery">重置</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="content-main">
        <div class="top-left" style="margin-bottom: 5px">
          <el-button @click="inputRedInvoiceClick" type="primary">红字发票确认信息录入</el-button>
          <el-button @click="handleRedNoticeResultRequest" v-if="equipment.billingChannel != 'QD-XSD'" type="primary">同步最新状态</el-button>
          <el-button @click="redNoticeResultVisible = true" v-else type="primary">同步最新状态</el-button>
          <el-button   @click="synchronizationData = true"  type="primary" >同步数据</el-button>
          <!--  -->
        </div>
        <div class="content-table">
          <el-table ref="multipleTable" :data="tableData" border stripe v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelectionChange" style="width: 100%">
            <el-table-column type="selection" width="70" fixed="left" />
            <el-table-column type="index" label="序号" width="60" />
            <el-table-column prop="redSource" label="购销身份" :formatter="handleTableValueFormat" width="180" />
            <el-table-column label="购方名称" show-overflow-tooltip prop="buyerName" width="180" />
            <el-table-column label="购方税号" show-overflow-tooltip prop="buyerTaxNo" width="180" />
            <el-table-column prop="redNoticeNo" show-overflow-tooltip label="红字确认单编号" width="180" />
            <el-table-column prop="redInvoiceNo" show-overflow-tooltip label="红票发票号码" width="120" />
            <el-table-column prop="sellerName" show-overflow-tooltip label="销方名称" width="180" />
            <el-table-column prop="sellerTaxNo" show-overflow-tooltip label="销方税号" width="120" />
            <el-table-column prop="amount" label="金额" width="180" />
            <el-table-column prop="taxAmount" label="税额" width="180" />
            <el-table-column prop="sumAmount" label="价税合计" width="120" />
            <el-table-column prop="applyStatus" label="申领状态" :formatter="handleTableValueFormat" width="120" />
            <el-table-column prop="billMessage" show-overflow-tooltip label="失败原因" width="120" />
            <el-table-column prop="invoiceStatus" label="发票状态" :formatter="handleTableValueFormat" width="120" />
            <el-table-column prop="confirmStatus" label="确认状态" :formatter="handleTableValueFormat" width="120" />
            <el-table-column prop="invoiceTime" show-overflow-tooltip label="开票日期" :formatter="handleTableValueFormat" width="120" />
            <el-table-column prop="created" label="提交日期" show-overflow-tooltip :formatter="handleTableValueFormat" width="120" />
            <el-table-column prop="enterTime" label="录入日期" show-overflow-tooltip :formatter="handleTableValueFormat" width="120" />
            <el-table-column prop="operate" label="操作" fixed="right" width="250">
              <template slot-scope="scope">
                <el-button type="text" style="padding: 0" @click="handleShowDetail(scope.row)">详情</el-button>
                <el-button type="text" style="padding: 0" @click="handleBillToRed(scope.row)" :disabled="(scope.row.confirmStatus != 'YQR' && scope.row.confirmStatus != 'WXQR') || (scope.row.invoiceStatus == 'YKJ')">开票</el-button>
                <el-button type="text" style="padding: 0" @click="handleRedNoticeRepeal(scope.row)" :disabled="scope.row.confirmStatus != 'WQR'">撤销</el-button>
                <el-button type="text" style="padding: 0" @click="handleApplyResultQuery(scope.row)" v-if="equipment.billingChannel != 'QD-XSD'">查询</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="content-page">
          <el-pagination class="p-page" :current-page.sync="selectForm.page" :page-size="selectForm.size" :total="page.total" layout="total,sizes,prev,pager,next" :page-sizes="[15, 30, 50, 100]" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
      <el-dialog append-to-body title="" width="420px" :visible.sync="billingTradeVisible">
        <div class="dialog-trade-generate" style="margin-top: -40px;">
          发票开具成功!
        </div>
        <div class="dialog-footer" slot="footer">
          <el-button type="default" @click="billingTradeVisible = false">取消</el-button>
          <el-button type="primary" @click="billingTradeVisible = false">确认</el-button>
        </div>
      </el-dialog>
      <el-dialog append-to-body title="" width="420px" :visible.sync="redNoticeResultVisible">
        <div class="dialog-trade-generate">
          <div>
            <label>当前组织：</label>
            <organization-select :is-add-all="true" @handleSelectAction="handleCurrentOrganizations"></organization-select>
          </div>
          <div style="margin-top: 10px">
            <label>查询日期：</label>
            <el-date-picker v-model="queryTimeRange" style="width: 220px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </div>
        </div>
        <div class="dialog-footer" slot="footer">
          <el-button type="default" @click="redNoticeResultVisible = false">取消</el-button>
          <el-button type="primary" @click="handleRedNoticeXSDRequest">确认</el-button>
        </div>
      </el-dialog>
    </template>

    <!-- 红票详情 -->
    <BillingRedInvoiceDetail :show="isShowDetail" :invoice="redNotice" @close="isShowDetail = false" />
    <!-- 同步数据 -->
    <div class="synchronization_style">
      <el-dialog
        title="同步数据"
        :visible.sync="synchronizationData"
        width="30%">
        <el-form :model="formData" :rules="rules" ref="ruleForm">
          <el-form-item prop="orgId" style="text-align:left;margin-bottom:15px" label="当前组织：" :label-width="formLabelWidth">
            <OrganizationSelect :is-add-all="true" :model="formData"></OrganizationSelect>
          </el-form-item>
          <el-form-item prop="queryTimeRange" style="text-align:left;margin-bottom:15px" label="开票日期：" :label-width="formLabelWidth">
            <el-date-picker
              v-model="formData.queryTimeRange"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item style="text-align:left;margin-bottom:15px" label="红字通知单号：" :label-width="formLabelWidth">
            <el-input v-model="formData.redNoticeNo" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="synchronizationData = false">取 消</el-button>
          <el-button type="primary" @click="handlerSynchronizationData">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    
  </div>
</template>

<script>
import { tableStyle } from '@/util/mixins';
import RedInvoiceInfoInput from './components/RedInvoiceInfoInput';
import { getBillingRedNoticeDetails, getRedNoticeByPage, getBillingRedNoticeApplyResult, handleApplyResultSingleQuery, getBillingRedNoticeResultRequest, getBillingRedNoticeSendMeDetails, handleRedNoticeRepeal, redNoticeSyncData } from '@/service/invoice-open';
import { handleElectricRedTicket } from '@/service/sal/invoice';
import BillingRedInvoiceDetail from './components/BillingRedInvoiceDetail.vue';
import OrganizationSelect from "@/components/widgets/OrganizationSelect";

export default {
  name: 'BillingRedInvoiceList',
  mixins: [tableStyle],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    RedInvoiceInfoInput,
    BillingRedInvoiceDetail,
    OrganizationSelect
  },
  props: {
    equipment: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      isShowRedInvoiceInfo: false,
      isShowDetail: false,
      invoiceDetail: {},
      redNotice: {},
      blueInvoiceId: null,
      tableData: [],
      tableLoading: false,
      selections: [], // 勾选项
      selectForm: {
        redSource: 'seller',
        buyerName: undefined,
        sellerName: undefined,
        confirmStatus: 'WQR',
        applyStatus: '',
        invoiceTimeRange: [],
        enterTimeRange: [],
        page: 1,
        size: 15
      },
      page: {
        total: 0,
        size: 15,
        current: 1
      },
      invoiceStatus: [
        { label: '已开具', value: 'YKJ' },
        { label: '未开具', value: 'WKP' },
        { label: '开具中', value: 'KJZ' },
        { label: '开具失败', value: 'KJSB' }
      ],
      applyStatus: [
        { label: '全部', value: '' },
        { label: '申领成功', value: '3' },
        { label: '申领失败', value: '4' }
      ],
      confirmStatus: [
        { label: '无需确认', value: 'WXQR' },
        { label: '已确认', value: 'YQR' },
        { label: '未确认', value: 'WQR' },
        { label: '已撤回', value: 'YCH' },
        { label: '已作废', value: 'YZF' }
      ],
      billingInvoice: {},
      billingTradeVisible: false,
      redNoticeResultVisible: false,
      synchronizationData:false,
      formData: {
        orgId:0,
        queryTimeRange:[],
        redNoticeNo: '',
      },
      formLabelWidth: '150px',
      redNoticeSyncStatusResultVisible: false,
      drawer: false,
      pullDataStatus: true,
      queryTimeRange: [],
      orgId: 0,
      todayTime:'',
      yesterdayTime:'',
      rules: {
        queryTimeRange: [
          { required: true, message: '请选择开票日期', trigger: 'change' }
        ],
        orgId: [
          { required: true, message: '请选择当前组织', trigger: 'change' }
        ],
      }
    };
  },
  created() {
    const { invoiceId, stepActive } = this.$route.query;
    if (invoiceId > 0 && stepActive == 2) {
      this.isShowRedInvoiceInfo = true;
      return;
    }
    this.getList();
    var date = new Date();

    var nowMonth = date.getMonth() + 1;
    var strDate = date.getDate();
    var seperator = "-";
    if (nowMonth >= 1 && nowMonth <= 9) {
      nowMonth = "0" + nowMonth;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var todayTime = date.getFullYear() + seperator + nowMonth + seperator + strDate;
    var time = (new Date).getTime() - 24 * 60 * 60 * 1000;
    var date = new Date(time);
    var nowMonth = date.getMonth() + 1;
    var strDate = date.getDate();
    var seperator = "-";
    if (nowMonth >= 1 && nowMonth <= 9) {
      nowMonth = "0" + nowMonth;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var yesterdayTime = date.getFullYear() + seperator + nowMonth + seperator + strDate;
    this.formData.queryTimeRange[1] = todayTime
    this.formData.queryTimeRange[0] = yesterdayTime
  },
  methods: {
    async redNoticeSyncData(){
      let items = this.selections;
      this.ids = items.map((item) => item.id);
      let param = {
        startSource: 'MESEND',
        redNoticeNo:this.formData.redNoticeNo,
        orgId: this.formData.orgId,
        queryTimeRange: this.formData.queryTimeRange,
        ids:this.ids
      }
      let res = await redNoticeSyncData(param)
      if(res.success){
        this.synchronizationData = false
        this.toast('数据已同步成功!','success')
      }
    },
    handlerSynchronizationData(){
      console.log(this.formData,'formData')    
      this.redNoticeSyncData()
    },
    async handleShowDetail(item) {
      const { success, data } = await getBillingRedNoticeSendMeDetails(item.id);
      if (success) {
        this.redNotice = data;
      }
      this.isShowDetail = true;
    },
    handlerClone(data){
      this.isShowRedInvoiceInfo = data
    },
    async handleRedNoticeRepeal(item) {
      this.$confirm('是否撤回此确认单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        handleRedNoticeRepeal({id: item.id}).then(res => {
          if (res.success) {
            this.toast('撤回成功!', 'success');
          }
        }).catch(err => {
          this.toast('撤回失败!', 'warning');
        });

      }).catch(() => {
      });
    },
    /**  */
    async getList() {
      this.selectForm['startSource'] = 'MESEND';
      this.tableLoading = true;
      const { success, data } = await getRedNoticeByPage(this.selectForm);
      this.tableLoading = false;
      if (success) {
        this.page = data;
        this.tableData = data.records;
      }
    },
    //申请结果查询
    async handleApplyResultQuery(row) {
      const { success, data } = await handleApplyResultSingleQuery({id: row.id, startSource: 'MESEND'});
      this.tableLoading = false;
      if (success) {
        this.toast('确认单同步成功!', 'success');
      }
    },
    async handleBillToRed(row) {
      this.$confirm('发票开具, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          handleElectricRedTicket({id: row.id}).then(res => {
          this.tableLoading = false;
          if (res.success) {
            this.billingTradeVisible = true
          }
        }).catch(err => {
            this.toast('发票开具失败!', 'warning');
        });

      }).catch(() => {
        this.toast('取消发票开具!', 'warning');
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.page.current = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.selectForm.redSource = 'seller';
      this.selectForm.buyerName = undefined;
      this.selectForm.sellerName = undefined;
      this.selectForm.confirmStatus = 'WQR';
      this.selectForm.applyStatus = '';
      this.selectForm.invoiceTimeRange = [];
      this.selectForm.createdTimeRange = [];
      this.selectForm.enterTimeRange =  [];
    },
    handleSizeChange(val) {
      this.selectForm.size = val;
      this.selectForm.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.selectForm.page = val;
      this.getList();
    },
    //红字确认单结果同步查询
    async handleRedInvoiceApplyResult() {
      const { success, data } = await getBillingRedNoticeApplyResult(this.selectForm);
      if (success) {
        this.toast('确认单数据已同步', 'success');
      }
      this.redNoticeSyncStatusResultVisible = false
    },
    // 表格勾选
    handleSelectionChange(val) {
      let ids = [];
      this.selections = val;
      console.log(this.selections.length);
    },
    //红字确认单结果查询异步请求
    async handleRedNoticeResultRequest() {
      let items = this.selections;
      if (items.length < 1) {
        this.toast('请选择要同步的数据！', 'waring');
        return;
      }
      this.ids = items.map((item) => item.id);
      this.toast('开始同步确认单状态', 'success');
      const { success, data } = await getBillingRedNoticeResultRequest({ids: this.ids, startSource: 'MESEND'});
      if (success) {
        this.toast('同步确认单状态结束', 'success');
      }
    },
    handleCurrentOrganizations (model) {
      this.orgId = model.orgId
    },
    //红字确认单结果查询机器人X版
    async handleRedNoticeXSDRequest() {
      if(!this.orgId > 0){
        this.toast('请选择同步组织', 'waring');
        return;
      }
      if(this.queryTimeRange == null || this.queryTimeRange.length == 0){
        this.toast('请选择同步日期', 'waring');
        return;
      }
      this.toast('开始同步确认单状态', 'success');
      const { success, data } = await handleApplyResultSingleQuery({startSource: 'MESEND', orgId: this.orgId, queryTimeRange: this.queryTimeRange});
      if (success) {
        this.toast('同步确认单状态结束', 'success');
      }
      this.redNoticeResultVisible = false
    },
    inputRedInvoiceClick() {
      this.invoiceId = '';
      this.isShowRedInvoiceInfo = true;
    },
    handleCloseApply() {
      this.isShowRedInvoiceInfo = false;
    },
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '购销身份':
          return '销售方';
        case '发票状态':
          var invoiceStatus = this.invoiceStatus.find((item) => item.value == row.invoiceStatus);
          if (invoiceStatus != null && invoiceStatus != '') {
            return invoiceStatus.label;
          }
          return '未开具';
        case '确认状态':
          var confirmStatus = this.confirmStatus.find((item) => item.value == row.confirmStatus);
          if (confirmStatus != null) {
            return confirmStatus.label;
          }
          return row.confirmStatus;
        case '申领状态':
          var applyStatus = this.applyStatus.find((item) => item.value == row.applyStatus);
          if (applyStatus != null) {
            return applyStatus.label;
          }
          return row.applyStatus;
        case '开票日期':
          return row.invoiceTime ? row.invoiceTime.substring(0, 10) : '--';
        case '提交日期':
          return row.created ? row.created.substring(0, 10) : '--';
        case '录入日期':
          return row.enterTime ? row.enterTime.substring(0, 10) : '--';
        default:
          return value;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.billing_red_invoice_list_view {
  // position: relative;
  .select-content {
    padding: 20px 0 20px 20px;
  }
}
.detail-row {
  text-align: left;
  margin-top: 30px;
  margin-left: 150px;

  .detail-row-info {
    display: flex;
  }
}
.synchronization_style {
  ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner, .el-input {
    width: 240px;
  }
  ::v-deep .el-input {
    width: 240px;
  }
  ::v-deep .el-form-item__error {
    top: 30px;
    font-size: 12px;
  }
 
}
</style>
