<template>
  <div class="login_logs_view">
    <div class="content-select">
      <div class="select-content">
        <el-form ref="filterForm" :model="selectForm" inline label-width="110px">
          <el-row class="row-first">
            <el-col :span="8">
              <el-form-item label="购/销方身份" prop="title">
                <el-select v-model="selectForm.redSource" placeholder="购/销方身份" clearable style="width: 220px">
                  <el-option v-for="dict in redSource" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="购方名称" prop="status">
                <el-input v-model="selectForm.buyerName" placeholder="请输入购方名称" clearable style="width: 220px" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="销方名称" prop="status">
                <el-input v-model="selectForm.sellerName" placeholder="请输入销方名称" clearable style="width: 220px" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row-first">
            <el-col :span="8">
              <el-form-item label="确认状态" prop="status">
                <el-select v-model="selectForm.confirmStatus" placeholder="操作状态" clearable style="width: 220px">
                  <el-option v-for="dict in confirmStatus" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收到日期" prop="loginTime">
                <el-date-picker v-model="selectForm.createdTimeRange" style="width: 220px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" @click="handleQuery">查询</el-button>
              <el-button type="default" @click="resetQuery">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div class="top-left" style="margin-bottom: 16px">
        <el-button @click="handlePullDataClick" type="primary">拉取数据</el-button>
        <el-button @click="redNoticePullDataResultVisible = true" type="primary">获取拉取结果</el-button>
        <el-button @click="handleConfirmBatchClick" type="primary">批量确认</el-button>
        <el-button @click="handleRedNoticeSyncStatusBatch" type="primary">数据同步</el-button>
      </div>
      <div class="content-table">
        <el-table ref="multipleTable" :data="tableData" border stripe v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelectionChange" style="width: 100%">
          <el-table-column type="selection" width="70" fixed="left" />
          <el-table-column type="index" label="序号" width="60" />
          <el-table-column prop="redSource" label="购销身份" :formatter="handleTableValueFormat" width="180" />
          <el-table-column prop="buyerName" label="购方名称" show-overflow-tooltip width="180" />
          <el-table-column prop="buyerTaxNo" label="购方税号" show-overflow-tooltip width="120" />
          <el-table-column prop="invoiceNo" label="对应蓝票全电号码" show-overflow-tooltip width="120" />
          <el-table-column prop="redNoticeNo" label="红字确认单编号" show-overflow-tooltip width="180" />
          <el-table-column prop="amount" label="金额" width="120" />
          <el-table-column prop="taxAmount" label="税额" width="120" />
          <el-table-column prop="sumAmount" label="价税合计" width="120" >
            <template slot-scope="scope">
              {{ scope.row.amount + scope.row.taxAmount }}
            </template>
          </el-table-column>
          <el-table-column prop="confirmStatus" label="确认状态" :formatter="handleTableValueFormat" width="120" />
          <el-table-column prop="confirmTime" show-overflow-tooltip label="确认日期" :formatter="handleTableValueFormat" width="120" />
          <el-table-column prop="created" show-overflow-tooltip label="收到日期" :formatter="handleTableValueFormat" width="120" />
          <el-table-column prop="sellerName" label="销方名称" show-overflow-tooltip width="180" />
          <el-table-column prop="sellerTaxNo" label="销方税号" show-overflow-tooltip width="120" />
          <el-table-column prop="operate" label="操作" fixed="right" width="250">
            <template slot-scope="scope">
              <el-button type="text" style="padding: 0" @click="handleDetail(scope.row)">查看</el-button>
              <el-button type="text" style="padding: 0" :disabled="scope.row.confirmStatus != 'WQR'" @click="handleConfirmClick(scope.row)">确认</el-button>
              <el-button type="text" style="padding: 0" @click="handleApplyResultQuery(scope.row)">查询</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <!-- 分页组件 -->
        <el-pagination class="p-page" :current-page.sync="selectForm.page" :page-size="selectForm.size" :total="page.total" layout="total,sizes,prev,pager,next" :page-sizes="[15, 30, 50, 100]" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
      </div>
      <!-- 确认弹框 -->
      <el-dialog append-to-body title="提示" :visible.sync="confirmBatchVisible" width="510px">
        <div class="limit-dialog" style="margin-top: -40px">
          <div class="message">你确定要通过这批确认单吗?</div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleConfirmBatch('N')">拒绝</el-button>
          <el-button type="primary" @click="handleConfirmBatch('Y')">确认</el-button>
        </div>
      </el-dialog>
      <el-dialog append-to-body title="提示" :visible.sync="confirmVisible" width="510px">
        <div class="limit-dialog" style="margin-top: -40px">
          <div class="message">你确定要通过该确认单吗?</div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleConfirm('N')">拒绝</el-button>
          <el-button type="primary" @click="handleConfirm('Y')">确认</el-button>
        </div>
      </el-dialog>

      <el-dialog append-to-body :visible.sync="noticeDetailVisible" width="1144px">
        <AboutMeInvoiceDetail :redNotice="redNoticeInvoice" :info="billingInvoice" />
      </el-dialog>
      <el-dialog append-to-body title="拉取数据请求" width="420px" :visible.sync="redNoticePullDataVisible">
        <div class="dialog-trade-generate" style="margin-top: -40px">
          <div>
            <label>当前组织：</label>
            <organization-select :is-add-all="true" :model="selectForm"></organization-select>
          </div>
          <div style="margin-top: 10px;">
            <label>查询日期：</label>
            <el-date-picker v-model="selectForm.queryTimeRange" style="width: 220px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </div>
        </div>
        <div class="dialog-footer" slot="footer">
          <el-button type="default" @click="redNoticePullDataVisible = false">取消</el-button>
          <el-button type="primary" @click="handleRedNoticePullData">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getBillingRedNoticeSendMeDetails,
  getRedNoticeListByPage,
  handleRedNoticeConfirmBatch,
  handleRedNoticePullSendMeRequest,
  getBillingRedNoticeResultRequest,
  handleApplyResultSingleQuery,
  handleRedNoticeConfirm
} from '@/service/invoice-open';
import AboutMeInvoiceDetail from './components/AboutMeInvoiceDetail';
import { selectInvoice } from '@/service/sal/invoice';
import { tableStyle, changeMethods } from '@/util/mixins';
import OrganizationSelect from "@/components/widgets/OrganizationSelect";

export default {
  name: 'RedInvoiceSendMeList',
  mixins: [tableStyle, changeMethods],
  components: {
    AboutMeInvoiceDetail,
    OrganizationSelect
  },
  props: {
    equipment: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      selectForm: {
        redSource: 'seller',
        invoiceStatus: null,
        buyerName: null,
        sellerName: null,
        createdTimeRange: [],
        queryTimeRange: [],
        orgId: undefined,
        confirmStatus: 'WQR',
        ids: [],
        page: 1,
        size: 15
      },
      page: {
        total: 0,
        size: 15,
        current: 1
      },
      invoiceStatus: [
        { label: '已开具', value: 'YKJ' },
        { label: '未开具', value: 'WKP' },
        { label: '开具中', value: 'KJZ' },
        { label: '开具失败', value: 'KJSB' }
      ],
      confirmStatus: [
        { label: '无需确认', value: 'WXQR' },
        { label: '已确认', value: 'YQR' },
        { label: '未确认', value: 'WQR' },
        { label: '已撤回', value: 'YCH' },
        { label: '已作废', value: 'YZF' }
      ],
      redSource: [
        { label: '销售方', value: 'seller' },
        { label: '购买方', value: 'buyer' }
      ],
      noticeDetailVisible: false,
      redNoticePullDataVisible: false,
      redNoticeSyncStatusVisible: false,
      redNoticeSyncStatusResultVisible: false,
      redNoticePullDataResultVisible: false,
      confirmBatchVisible: false,
      confirmVisible: false,
      billingInvoice: {},
      redNoticeInvoice: {},
      selections: [],
      pullDataStatus: true,
      syncStatus: true
    };
  },

  created() {
    this.getList();
  },
  methods: {
    /**  */
    async getList() {
      this.selectForm['startSource'] = 'SENDME';
      this.selectForm['systemSource'] = 'QD'
      this.tableLoading = true
      const { success, data } = await getRedNoticeListByPage(this.selectForm);
      this.tableLoading = false;
      if (success) {
        this.page = data;
        this.tableData = data.records;
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.page.current = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.selectForm.redSource = 'seller';
      this.selectForm.invoiceStatus = null;
      this.selectForm.buyerName = null;
      this.selectForm.sellerName = null;
      this.selectForm.createdTimeRange = [];
      this.selectForm.queryTimeRange = [];
      this.selectForm.orgId = undefined;
      this.selectForm.ids = [];
      this.selectForm.confirmStatus = 'WQR';
    },
    handleSizeChange(val) {
      this.selectForm.size = val;
      this.selectForm.page = 1;
      this.getList();
    },
    handleSelectionChange(val) {
      let ids = [];
      this.selections = val;
    },
    handleCurrentChange(val) {
      this.selectForm.page = val;
      this.getList();
    },
    handleConfirmClick(row) {
      if(!this.checkBillingChannel()){
        this.toast('该组织默认设备不支持此功能！', 'warning');
        return;
      }
      if(row.confirmStatus != 'WQR'){
        this.toast('不能选择已确认的数据!', 'warning')
        return;
      }
      this.selectForm.ids[0] = row.id
      this.confirmVisible = true
    },
    async handleConfirm(status) {
      let confirmStatus = status == 'Y' ? '确认' : '拒绝';
      const { success } = await handleRedNoticeConfirm({
        billingRedNotice: {id: this.selectForm.ids[0]},
        confirmStatus: status
      });
      if (success) {
        this.toast('红字确认单已提交'+ confirmStatus +'状态！', 'success');
        this.selectForm.ids = []
        this.getList();
      } else {
        this.toast('红字确认单提交'+ confirmStatus +'状态失败！', 'warning');
      }
      this.confirmVisible = false;
    },
    handlePullDataClick() {
      if(!this.checkBillingChannel()){
        this.toast('该组织默认设备不支持此功能！', 'warning');
        return;
      }
      this.redNoticePullDataVisible = true
    },
    handleConfirmBatchClick() {
      if(!this.checkBillingChannel()){
        this.toast('该组织默认设备不支持此功能！', 'warning');
        return;
      }
      let items = this.selections;
      if (items.length == 0) {
        this.toast('请至少选择一条数据！', 'warning');
        return;
      }
      let yqrList = items.filter((item) => item.confirmStatus != 'WQR');
      if (yqrList.length > 0) {
        this.toast('红字确认单确定申请列表选择中，不能包括已确认的数据！', 'warning');
        return;
      }
      this.selectForm.ids = items.map((item) => item.id);
      this.confirmBatchVisible = true;
    },
    async handleConfirmBatch(status) {
      let confirmStatus = status == 'Y' ? '确认' : '拒绝';
      const { success } = await handleRedNoticeConfirmBatch({
        billingRedNotice: {ids: this.selectForm.ids},
        confirmStatus: status
      });
      if (success) {
        this.toast('红字确认单已提交'+ confirmStatus +'状态！', 'success');
        this.selectForm.ids = []
        this.getList();
      } else {
        this.toast('红字确认单提交'+ confirmStatus +'状态失败！', 'warning');
      }
      this.confirmBatchVisible = false;
    },
    //申请结果查询
    async handleApplyResultQuery(row) {
      if(!this.checkBillingChannel()){
        this.toast('该组织默认设备不支持此功能！', 'warning');
        return;
      }
      const { success, data } = await handleApplyResultSingleQuery({id: row.id, startSource: 'SENDME'});
      this.tableLoading = false;
      if (success) {
        this.toast('确认单同步成功!', 'success');
      }
    },
    async handleRedNoticeSyncStatusBatch() {
      if(!this.checkBillingChannel()){
        this.toast('该组织默认设备不支持此功能！', 'warning');
        return;
      }
      let items = this.selections;
      if (items.length < 1) {
        this.toast('请选择要同步的数据！', 'waring');
        return;
      }
      this.ids = items.map((item) => item.id);
      this.toast('开始同步确认单状态', 'success');
      const { success, data } = await getBillingRedNoticeResultRequest({ids: this.ids, startSource: 'SENDME'});
      if (success) {
        this.toast('同步确认单状态结束', 'success');
      }
      this.redNoticeResultVisible = false
    },
    async handleRedNoticePullData() {
      if(!this.checkBillingChannel()){
        this.toast('该组织默认设备不支持此功能！', 'warning');
        return;
      }
      if(!this.selectForm.orgId > 0){
        this.toast('请选择组织！', 'warning');
        return;
      }
      if(this.selectForm.queryTimeRange == null || this.selectForm.queryTimeRange.length == 0){
        this.toast('请选择日期！', 'warning');
        return;
      }
      this.selectForm['startSource'] = 'SENDME';
      const { success } = await handleRedNoticePullSendMeRequest(this.selectForm);
      if (success) {
        this.toast('已发起查询请求，请稍后获取查询结果！', 'success');
        this.pullDataStatus = false
      } else {
        this.toast('数据同步失败！', 'warning');
      }
      this.redNoticePullDataVisible = false;
    },
    checkBillingChannel() {
      this.equipment.billingChannel
      if(this.equipment == null) {
        return false;
      }
      switch (this.equipment.billingChannel){
        case 'QD-XSD':
          return false;
        default:
          return true;
      }
    },
    async handleDetail(row) {
      const { success, data } = await getBillingRedNoticeSendMeDetails(row.id);

      this.noticeDetailVisible = false;
      if (success) {
        let items = data.items
        items.forEach((a) => {
          a['sumAmount'] = a['amount']+ a['taxAmount']
        });
        this.redNoticeInvoice = data
        this.billingInvoice['items'] = items;
        this.billingInvoice['invoiceNo'] = data.invoiceNo;
        this.billingInvoice['redNoticeNo'] = row['redNoticeNo'];
        if (row.billingInvoiceId != 0) {
          this.billingInvoice['redSource'] = 'seller';
          this.billingInvoice['deductionType'] = '-1';
        }
        let sumAmount = this.billingInvoice.items.map((item) => Number(item.amount)).reduce((sum, amount) => sum + amount, 0);
        let sumTaxAmount = this.billingInvoice.items.map((item) => Number(item.taxAmount)).reduce((sum, taxAmount) => sum + taxAmount, 0);
        let sum = Number(sumAmount + sumTaxAmount).toFixed(2);
        this.billingInvoice['xxSumAmount'] = sum;
        this.billingInvoice['dxSumAmount'] = this.handleSmallToCapitalize(sum);
        this.noticeDetailVisible = true;
      }
    },
    formatReciprocalName(row) {
      if (row.redSource == 'seller') {
        return row.buyerName;
      } else {
        return row.sellerName;
      }
    },
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '购销身份':
          if (row.redSource == 'seller') {
            return '销售方';
          } else {
            return '购买方';
          }
        case '发票状态':
          var invoiceStatus = this.invoiceStatus.find((item) => item.value == row.invoiceStatus);
          if (invoiceStatus != null) {
            return invoiceStatus.label;
          }
          return row.invoiceStatus;
        case '确认状态':
          var confirmStatus = this.confirmStatus.find((item) => item.value == row.confirmStatus);
          if (confirmStatus != null) {
            return confirmStatus.label;
          }
          return row.confirmStatus;
        case '确认日期':
          return row.confirmTime ? row.confirmTime.substring(0, 10) : row.confirmTime;
        case '收到日期':
          return row.created ? row.created.substring(0, 10) : row.created;
        default:
          return value;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
// .login_logs_view {
// }
.detail-row {
  text-align: left;
  margin-top: 30px;
  margin-left: 150px;

  .detail-row-info {
    display: flex;
  }
}
</style>
